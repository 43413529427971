<template>
  <div>

    <div id="map">


      <v-card v-show="station_info" class="card-stationDetails">
        <v-card-title class="card-header white--text px-10 py-8 text-subtitle-1 text-uppercase">
          STATION : {{ stationDetail.STATION_ID_A}}
          <br>
          LOCATION : {{ stationDetail.LOCATION}}
          <br>
          LAST UPDATED : <template v-if="stationDetail != ''">
            {{
            new Date(stationDetail.DATETIME_A).toLocaleDateString("en-GB") +
            ", " +
            new Date(stationDetail.DATETIME_A).toLocaleTimeString()
            }}
          </template>
        </v-card-title>

        <v-card-text>
          <v-simple-table class="px-0 pt-4 pb-0" light dense>
            <template v-slot:default>
              <thead>
                <tr style="background: #073662 !important; color: white">
                  <td> Parameter</td>
                  <td> Value & Unit </td>
                  <td colspan="2">Class Category</td>
                  <td> </td>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> pH </td>
                  <template v-if=" stationDetail.pH != null">
                    <td> {{ stationDetail.pH }} </td>
                    <td> <span :class="'dot-'+stationDetail.PARAM_PH_CLASS"></span></td>
                    <td> Class {{ stationDetail.PARAM_PH_CLASS }} </td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>

                </tr>

                <tr>
                  <td> Dissolved Oxygen (Concentration) </td>
                  <template v-if=" stationDetail.DO_CON != null">
                    <td> {{ stationDetail.DO_CON }} (mg/L) </td>
                    <td> <span :class="'dot-'+stationDetail.PARAM_DO_SAT_CLASS"></span></td>
                    <td> Class {{ stationDetail.PARAM_DO_SAT_CLASS }} </td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>

                </tr>

                <tr>
                  <td> Total Suspended Solid</td>
                  <template v-if=" stationDetail.TSS != null">
                    <td> {{ stationDetail.TSS }} (mg/L) </td>
                    <td> <span :class="'dot-'+stationDetail.PARAM_TSS_CLASS"></span></td>
                    <td> Class {{ stationDetail.PARAM_TSS_CLASS }} </td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>

                </tr>

                <tr>
                  <td> Turbidity </td>
                  <template v-if=" stationDetail.TURBIDITY != null">
                    <td> {{ stationDetail.TURBIDITY }} (NTU) </td>
                    <td></td>
                    <td></td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>
                </tr>

                <tr>
                  <td> Ammoniacal Nitrogen </td>
                  <template v-if=" stationDetail.NH3N != null">
                    <td> {{ stationDetail.NH3N }} (mg/L) </td>
                    <td> <span :class="'dot-'+stationDetail.PARAM_NH3N_CLASS"></span></td>
                    <td> Class {{ stationDetail.PARAM_NH3N_CLASS }} </td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>

                </tr>

                <tr>
                  <td> Conductivity </td>
                  <template v-if=" stationDetail.CONDUCTIVITY != null">
                    <td> {{ stationDetail.CONDUCTIVITY }} (µS/cm) </td>
                    <td></td>
                    <td></td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>
                </tr>

                <tr>
                  <td> Water Level </td>
                  <template v-if=" stationDetail.WATER_LEVEL != null">
                    <td> {{ stationDetail.WATER_LEVEL }} (m) </td>
                    <td></td>
                    <td></td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>
                </tr>

                <tr>
                  <td> Flowrate</td>
                  <template v-if=" stationDetail.FLOWRATE != null">
                    <td> {{ stationDetail.FLOWRATE }} (m<sup>3</sup>/s) </td>
                    <td></td>
                    <td></td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>

                </tr>

                <tr>
                  <td> Station Picture(s) </td>
                  <td
                    v-if="stationDetail.STATION_PICTURE == '' && stationDetail.DEPLOYMENT_PICTURE == '' && stationDetail.UPSTREAM_PICTURE == ''  && stationDetail.DOWNSTREAM_PICTURE == ''">
                    Not Available </td>

                </tr>


              </tbody>
            </template>
          </v-simple-table>

          <v-row class="px-7 py-4">
            <v-col cols="auto" v-if="stationDetail.STATION_PICTURE != ''" align-self="center">
              <v-img :lazy-src="stationDetail.STATION_PICTURE" max-height="100" max-width="200" alt="station"
                :src="stationDetail.STATION_PICTURE" class="d-flex justify-center"></v-img>
              <div class="text-center">Station</div>
            </v-col>

            <v-col cols="auto" v-if="stationDetail.DEPLOYMENT_PICTURE != ''">
              <v-img :lazy-src="stationDetail.DEPLOYMENT_PICTURE" max-height="100" max-width="200"
                :src="stationDetail.DEPLOYMENT_PICTURE"></v-img>
              <div class="text-center">Deployment</div>
            </v-col>
          </v-row>
          <v-row class="px-7 py-0">
            <v-col cols="auto" v-if="stationDetail.UPSTREAM_PICTURE != ''">
              <v-img :lazy-src="stationDetail.UPSTREAM_PICTURE" max-height="100" max-width="200"
                :src="stationDetail.UPSTREAM_PICTURE"></v-img>
              <div class="text-center">Upstream</div>
            </v-col>
            <v-col cols="auto" v-if="stationDetail.DOWNSTREAM_PICTURE != ''">
              <v-img :lazy-src="stationDetail.DOWNSTREAM_PICTURE" max-height="100" max-width="200"
                :src="stationDetail.DOWNSTREAM_PICTURE"></v-img>
              <div class="text-center">Downstream</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>



    </div>


  </div>
</template>

<script>
  // -- import something here --

  import "ol/ol.css";

  import Map from "ol/Map";
  import View from "ol/View";
  import { defaults as defaultControls, ScaleLine } from "ol/control";
  import {
    Tile as TileLayer,
    Vector as VectorLayer,
    Group as GroupLayer,
    Image as ImageLayer
  } from "ol/layer";
  import { OSM, Vector as VectorSource, ImageArcGISRest } from "ol/source";
  import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
  import Feature from "ol/Feature";
  import { fromLonLat } from "ol/proj";
  import { Point } from "ol/geom";
  import Overlay from "ol/Overlay";

  // Axios for fetching data
  import axios from "axios";

  export default {

    data: () => ({

      mapIcon: {
        green: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#00ff08",
            }),
            stroke: new Stroke({
              color: "#ffffff ",
              width: 3,
            }),
            radius: 10,
          }),
        }),
        red: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#fb4343",
            }),
            stroke: new Stroke({
              color: "#fb4343",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        orange: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#fc8414",
            }),
            stroke: new Stroke({
              color: "#fc8414",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        grey: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#7c8a94",
            }),
            stroke: new Stroke({
              color: "#7c8a94",
              width: 1.25,
            }),
            radius: 8,
          })
        }),
        cyan: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#078a88",
            }),
            stroke: new Stroke({
              color: "#078a88",
              width: 1.25,
            }),
            radius: 8,
          })
        }),
      },

      //Declaration

      stationDetail: '',
      station_info: false,


      // Map Declaration

      map: null,
      view: null,
      extent: [102.74131288335045, 1.4281723365439034, 103.94465766511522, 2.4005721601922105],
      baseMapLayer: {
        topographicMap: new TileLayer({
          title: "Topographic Map",
          source: new OSM({
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        boundaryLayer: new ImageLayer({
          title: "Topographic Map",
          source: new ImageArcGISRest({
            url:
              "https://eqmp.scienotech.com.my/arcgis/rest/services/DEMARCATION/STATE/MapServer",
          }),
          visible: false,
          type: "layer",
        }),
        imageryMap: new TileLayer({
          title: "Imagery Map",
          source: new OSM({
            // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: true,
          type: "base",
        }),
        streetMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        googleStreetMap: new TileLayer({
          title: "Google Street",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
            // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        googleSateliteMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
      },
      pointStyle: {
        default: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#3381ff",
            }),
            stroke: new Stroke({
              color: "#0242ab",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        selected: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#00ffff",
            }),
            stroke: new Stroke({
              color: "#003300",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),

      }
    }),

    mounted() {
      this.initiateMap();
      this.getParamInfo();
      // this.transition();
    },

    methods: {

      transition() {
        setTimeout(() => {
          this.$router.push({ path: '/PublicDisplay/Homepage' }).catch(() => { });
        }
          , 10000);
      },

      initiateMap() {
        var source = new VectorSource();
        var vector = new VectorLayer({
          source: source,
        });

        var baseMap = new GroupLayer({
          layers: [
            this.baseMapLayer.topographicMap,
            this.baseMapLayer.imageryMap,
            this.baseMapLayer.streetMap,
            this.baseMapLayer.googleStreetMap,
            this.baseMapLayer.googleSateliteMap,
          ],
        });

        this.map = new Map({
          controls: defaultControls({
            zoom: false,
          }),
          target: "map",
          layers: [baseMap],
          view: new View({
            projection: "EPSG:4326",
            zoom: 1,
          }),
          loadTilesWhileAnimating: false,
          loadTilesWhileInteracting: false,
        });

        this.view = this.map.getView();
        this.view.fit(this.extent);

        this.map.on('moveend', this.onMoveEnd)

        this.onClickMap();

      },

      onClickMap() {
        let that = this;

        this.map.on("click", function (evt) {
          console.log("hehe");
        });
      },

      onMoveEnd(evt) {

        console.log(evt.pixel);
        // console.log(this.view);

        const map = evt.map;
        console.log("extent2", map.getView().calculateExtent())
        // console.log(fromLonLat(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
      },

      zoomStation(longitude, latitude, station_id) {

        this.station_info = true;


        if (station_id == "WQMS06") {

          this.view.animate({
            center: [longitude, latitude],
            duration: 10000,
            zoom: 15,
            projection: "EPSG:4326",
          });
        }

        else {

          this.view.animate({
            center: [longitude, latitude],
            duration: 2000,
            zoom: 15,
            projection: "EPSG:4326",
          });

        }


        // setTimeout(()=> {
        //     this.zoomOutStation();
        //   }
        //   ,6000);



      },

      zoomOutStation() {

        this.station_info = false;
        this.view = this.map.getView();
        this.view.fit(this.extent, {
          duration: 2000,
        });



      },


      getParamInfo() {
        axios
          .get(this.globalUrl + "/bakaj/paramspublic", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.paramDetails = response.data;

            this.getStationInfo();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      getStationInfo() {
        axios
          .get(this.globalUrl + "/bakaj/readingpublic", {
            // .get("https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.stationDetails = response.data;

            this.addMapMarker();

            //   this.getStationInfoList();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      addMapMarker() {
        //   this.addborderLayer();

        for (let i = 0; i < this.stationDetails.length; i++) {
          var marker = new Feature({
            geometry: new Point([
              this.stationDetails[i].LONGITUDE,
              this.stationDetails[i].LATITUDE,
            ]),
            description: this.stationDetails[i],
          });
          var vectorSource = new VectorSource({
            features: [marker],
          });



          var markerVectorLayer = new VectorLayer({
            name: "Automated",
            source: vectorSource,
            style: this.stationDetails[i].GREEN == "TRUE"
              ? this.mapIcon.green
              : this.stationDetails[i].GREY == "TRUE"
                ? this.mapIcon.grey
                : this.stationDetails[i].YELLOW == "TRUE"
                  ? this.mapIcon.green
                  : this.mapIcon.green,
          });

          this.map.addLayer(markerVectorLayer);
        }
        // this.addMapOverlayReading();

        this.addMapOverlay();
      },

      addMapOverlay() {
        for (let i = 0; i < this.stationDetails.length; i++) {

          let labelStationID = "label_" + this.stationDetails[i].STATION_ID_A;

          let divLabel = document.createElement("DIV");

          divLabel.setAttribute("id", labelStationID);
          divLabel.setAttribute("ref", labelStationID);

          divLabel.setAttribute("style", "background-color:#5A5A5A;color: white !important;z-index: 100; letter-spacing: 1px; padding: 4px;");

          let positioning

          if (this.stationDetails[i].STATION_ID_A == 'WQMS06' || this.stationDetails[i].STATION_ID_A == 'WQMS05' || this.stationDetails[i].STATION_ID_A == 'WQMS01' || this.stationDetails[i].STATION_ID_A == 'WQMS04')
            positioning = [-105, -15]
          else if (this.stationDetails[i].STATION_ID_A == 'WQMS03')
            positioning = [100, -13]
          else if (this.stationDetails[i].STATION_ID_A == 'WQMS02')
            positioning = [123, -13]
          else
            positioning = [0, -55]



          divLabel.innerHTML = this.stationDetails[i].STATION_ID_A + ", " + this.stationDetails[i].LOCATION;

          document.getElementById("map").appendChild(divLabel);


          let overlayLabel = new Overlay({
            element: document.getElementById(labelStationID),
            id: "label__" + this.stationDetails[i].STATION_ID_A,
            positioning: "top-center",
            offset: positioning
          });

          overlayLabel.setPosition([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]);

          this.map.addOverlay(overlayLabel);
        }

        setTimeout(() => {
          this.getStationDetail('WQMS01');
        }
          , 1 * 5000);

        // this.getStationDetail('WQMS01');

        setTimeout(() => {
          this.getStationDetail('WQMS02');
        }
          , 1 * 15000);

        setTimeout(() => {
          this.getStationDetail('WQMS03');
        }
          , 2 * 15000);

        setTimeout(() => {
          this.getStationDetail('WQMS04');
        }
          , 3 * 15000);

        setTimeout(() => {
          this.getStationDetail('WQMS05');
        }
          , 4 * 15000);

        setTimeout(() => {
          this.getStationDetail('WQMS06');
        }
          , 5 * 15000);

        setTimeout(() => {
          this.$router.push({ path: '/PublicDisplay/Homepage' }).catch(() => { })
        }
          , 6 * 15000);
      },

      getStationDetail(station_id) {




        for (let i = 0; i < this.stationDetails.length; i++) {
          if (this.stationDetails[i].STATION_ID_A == station_id) {
            this.stationDetail = this.stationDetails[i];
          }
        }

        this.zoomStation(this.stationDetail.LONGITUDE, this.stationDetail.LATITUDE, station_id)


      }

    },

  };
</script>

<style lang="scss" scoped>
  @import '~scss/main';


  #map {
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: #082013;
  }

  .card-stationDetails {
    position: absolute;
    top: 50px;
    right: 40px;
    z-index: 15;
    width: 560px;
    overflow: auto;
  }

  #map::v-deep .dot-I {
    height: 12px;
    width: 12px;
    background-color: #2d7ef8;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-II {
    height: 12px;
    width: 12px;
    background-color: #05F61D;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-III {
    height: 12px;
    width: 12px;
    background-color: #F5E700;
    border-radius: 50%;
    display: inline-block;
  }

  #map::v-deep .dot-IV {
    height: 12px;
    width: 12px;
    background-color: #F6C105;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 2s linear infinite;
  }

  #map::v-deep .dot-V {
    height: 12px;
    width: 12px;
    background-color: #F60505;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 2s linear infinite;
  }

  #map::v-deep .image-desc {
    margin: auto;
  }


  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
</style>